import * as React from 'react';
import { graphql } from 'gatsby';

import { Template, Split, ImageBlock } from 'common/layout';
import { NewPasswordForm } from 'modules/NewPasswordForm';

import ResetHeaderImage from 'images/password-forget.jpg';

const NewPassword = () => (
  <Template>
    <Split.Container>
      <Split.Content $first $hideOnMobile>
        <ImageBlock
          fullHeight
          logoInclude
          alt="PLTS login flow"
          src={ResetHeaderImage}
        />
      </Split.Content>
      <Split.Content $backgroundColor>
        <NewPasswordForm />
      </Split.Content>
    </Split.Container>
  </Template>
);

export default NewPassword;

export const query = graphql`
  query NewPassword($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;
